import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/contents.css'
import '../../static/css/article.css'
import '../../static/css/react-calendar-heatmap.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'
import '../../static/css/font_awesome.css'
import '../../static/css/floating.css'
import React, { useState } from 'react'
import Seo from '../components/Seo'
import { Header } from '../components/Header'
import { Contents } from '../components/Article'
import { Side } from '../components/Side'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import { Floating } from '../components/Floating'
import { useStaticQuery, graphql } from 'gatsby'
import { category } from '../config/category'
import { getResizeImgUrl } from '../util/format'
import { url } from '../config/domain'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import loadable from '@loadable/component'

const Script = loadable(() => import('../components/Adsense/Script'))

export default function Article({ location }: any): JSX.Element {
  const data = useStaticQuery(
    graphql`
      query {
        allArticle(sort: { order: DESC, fields: updatedAt___seconds }) {
          edges {
            node {
              category
              description
              id
              thumbnail
              title
              article
              createdAt {
                seconds
              }
              updatedAt {
                seconds
              }
            }
          }
        }
        allRecommend {
          edges {
            node {
              id
              res {
                desc
                title
                thumbnail
                url
              }
            }
          }
        }
      }
    `
  )

  const locatePath = location.pathname.split('/')
  const articleId = locatePath[2]

  const article = data.allArticle.edges.find(function (o: any) {
    return o.node.id === articleId
  })

  let recommend = data.allRecommend.edges.find(function (o: any) {
    return o.node.id === articleId
  })

  recommend = recommend === undefined ? [] : recommend.node.res

  let categoryData: {
    value: string
    name: string
  } = {
    value: '',
    name: '',
  }

  // 記事内容が子カテゴリーの場合に、parentに値が格納される。パンくずをいい感じに生成するため。
  let parent: undefined | any = undefined
  // 記事に紐づく、カテゴリー情報を取得する。
  // 親要素 -> 子要素の順で探索する。
  category.map((cate: any) => {
    if (cate.name === article.node.category) {
      categoryData = cate
    }
    if (cate.children.length > 0) {
      cate.children.map((child: any) => {
        if (article.node.title.includes(child.value)) {
          categoryData = child
          parent = cate
        }
      })
    }
  })

  const breadcrumb = parent
    ? [
        {
          link: '/',
          text: 'KURORO BLOG',
        },
        {
          link: '/' + (parent ? parent.name : '') + '/',
          text: parent ? parent.value : '',
        },
        {
          link: '/' + (categoryData ? categoryData.name : '') + '/',
          text: categoryData ? categoryData.value : '',
        },
        {
          link: '/' + article.node.category + '/' + article.node.id + '/',
          text: article.node.title,
        },
      ]
    : [
        {
          link: '/',
          text: 'KURORO BLOG',
        },
        {
          link: '/' + (categoryData ? categoryData.name : '') + '/',
          text: categoryData ? categoryData.value : '',
        },
        {
          link: '/' + article.node.category + '/' + article.node.id + '/',
          text: article.node.title,
        },
      ]

  const articleUrl = url + article.node.category + '/' + article.node.id + '/'

  const rssUrl = 'https://kuroro.blog/rss.xml'

  const [rssState, rssSetState] = useState({
    copied: false,
  })

  const [linkState, linkSetState] = useState({
    copied: false,
  })

  const rssHandleClick = () => {
    const timer = setTimeout(() => {
      rssSetState({ copied: false })
    }, 2 * 1000)
    timer
  }

  const linkHandleClick = () => {
    const timer = setTimeout(() => {
      linkSetState({ copied: false })
    }, 2 * 1000)
    timer
  }

  return (
    <>
      <div>
        <div className="wrapper">
          <Seo
            title={article.node.title}
            description={article.node.description}
            imagePath={article.node.thumbnail}
            contentType={'article'}
            url={articleUrl}
            article={article.node}
            imgList={[
              getResizeImgUrl(article.node.thumbnail, '350x232'),
              getResizeImgUrl(article.node.thumbnail, '690x460'),
            ]}
            noIndex={false}
          />
          <Header location={locatePath} />
          <main className="main">
            <div className="article__sns">
              <a
                href={
                  'https://twitter.com/share?url=' + articleUrl + '&text=' + article.node.title + '&hashtags=KURORO'
                }
                className="article__snsIcon"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <svg viewBox="0 0 27 27" width="40" height="40">
                  <path
                    fill="currentColor"
                    d="M23.1 8.7v.7c0 8-6.4 14.5-14.4 14.6h-.2C5.7 24 3 23.2.6 21.7c.4 0 .8.1 1.2.1 2.3 0 4.6-.8 6.3-2.1C6 19.6 4 18.2 3.3 16c.3.1.7.1 1 .1.5 0 .9-.1 1.4-.2-2.4-.5-4.1-2.6-4.1-5.1v-.1c.7.4 1.5.6 2.3.7-1.5-1-2.2-2.5-2.2-4.3 0-.9.2-1.8.7-2.6C5 7.8 8.8 9.7 13 9.9c-.1-.4-.1-.8-.1-1.2 0-2.8 2.2-5.2 5.2-5.2 1.5 0 2.8.6 3.8 1.7C23 5 24 4.6 25 4.1c-.4 1.2-1.2 2.1-2.2 2.8 1-.1 2-.4 2.9-.8-.8 1-1.7 1.8-2.6 2.6z"
                  />
                </svg>
              </a>
              <a
                href={'https://www.facebook.com/share.php?u=' + articleUrl}
                className="article__snsIcon"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <svg viewBox="0 0 27 27" width="40" height="40">
                  <path
                    fill="currentColor"
                    d="M13.5 1.7C7 1.7 1.7 7.1 1.7 13.6c0 5.9 4.4 10.7 10 11.7v-8.2h-3V13.7h2.9v-2.6c0-2.9 1.9-4.6 4.5-4.6 1.4 0 2.6.2 2.6.2v.1h.1v2.9h-1.5c-1.5 0-2 1-2 1.9v2.1h3.2l-.5 3.2h-2.6v8.2c5.7-.9 10-5.7 10-11.6C25.3 7.1 20 1.7 13.5 1.7z"
                  />
                </svg>
              </a>

              <a
                href={'https://b.hatena.ne.jp/entry/' + articleUrl}
                className="article__snsIcon"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <img
                  src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                  alt="このエントリーをはてなブックマークに追加"
                  width={40}
                  className="article__snsIcon"
                  height={40}
                />
              </a>

              <button className="article__snsIcon article__snsIconButton article__snsIconRssButton">
                <CopyToClipboard
                  text={rssUrl}
                  onCopy={() => {
                    rssSetState({ copied: true })
                    rssHandleClick()
                  }}
                >
                  <svg
                    version="1.1"
                    id="_x32_"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        className="st0"
                        d="M35.558,0.014v68.062c225.154,0,408.38,183.16,408.38,408.379H512C512,213.724,298.289,0.014,35.558,0.014z"
                        style={{ fill: 'rgb(255, 255, 255)' }}
                      ></path>
                      <path
                        className="st0"
                        d="M35.558,136.139v68.07c150.15,0,272.257,122.09,272.257,272.246h68.062
		C375.876,288.795,223.21,136.139,35.558,136.139z"
                        style={{ fill: 'rgb(255, 255, 255)' }}
                      ></path>
                      <path
                        className="st0"
                        d="M35.558,272.26v68.07c75.089,0,136.133,61.047,136.133,136.125h68.062
		C239.753,363.799,148.148,272.26,35.558,272.26z"
                        style={{ fill: 'rgb(255, 255, 255)' }}
                      ></path>
                      <path
                        className="st0"
                        d="M49.18,413.646c-27.16,0-49.18,22-49.18,49.168s22.02,49.172,49.18,49.172
		c27.159,0,49.179-22.004,49.179-49.172S76.339,413.646,49.18,413.646z"
                        style={{ fill: 'rgb(255, 255, 255)' }}
                      ></path>
                    </g>
                  </svg>
                </CopyToClipboard>
                {rssState.copied ? (
                  <div className="clipBoardText">RSS用リンクをクリップボードにコピーしました</div>
                ) : null}
              </button>

              <button className="article__snsIcon article__snsIconButton article__snsIconLinkButton">
                <CopyToClipboard
                  text={articleUrl}
                  onCopy={() => {
                    linkSetState({ copied: true })
                    linkHandleClick()
                  }}
                >
                  <svg
                    version="1.1"
                    id="_x32_"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        className="st0"
                        d="M509.445,113.129c-2.547-13.219-7.047-26.141-13.453-38.359c-6.391-12.203-14.75-23.641-24.938-33.828
		c-13.563-13.578-29.406-23.875-46.265-30.719c-25.297-10.219-52.828-12.781-79.266-7.656c-13.219,2.563-26.156,7-38.359,13.422
		c-12.172,6.422-23.641,14.75-33.828,24.953l-66.25,66.25c-13.375,13.344-13.375,35.047,0,48.391s35.031,13.344,48.391,0
		l66.25-66.281c7.031-7,15.016-12.172,23.594-15.672c12.844-5.203,27.031-6.531,40.547-3.906c6.75,1.313,13.328,3.594,19.531,6.844
		c6.188,3.25,12,7.469,17.281,12.734c7.031,7.078,12.187,15.047,15.687,23.609c5.203,12.844,6.531,27.047,3.906,40.547
		c-1.313,6.766-3.594,13.344-6.828,19.516c-3.281,6.219-7.484,12.031-12.765,17.313l-66.25,66.234
		c-13.359,13.359-13.359,35.047,0,48.391s35.016,13.344,48.375,0l66.25-66.265c13.594-13.563,23.875-29.406,30.703-46.266
		C512.008,167.083,514.555,139.551,509.445,113.129z"
                        style={{ fill: 'rgb(255, 255, 255)' }}
                      ></path>
                      <path
                        className="st0"
                        d="M256.54,356.426l-66.266,66.266c-7.047,7.016-15.031,12.188-23.594,15.672
		c-12.844,5.219-27.047,6.547-40.547,3.938c-6.766-1.328-13.328-3.625-19.531-6.859c-6.188-3.266-12-7.5-17.281-12.75
		c-7.031-7.063-12.203-15.031-15.688-23.609c-5.203-12.828-6.531-27.031-3.922-40.563c1.313-6.75,3.609-13.328,6.844-19.516
		c3.281-6.188,7.484-12,12.766-17.297l66.266-66.25c13.344-13.344,13.344-35.016,0-48.359c-13.375-13.359-35.031-13.359-48.391,0
		l-66.25,66.234c-13.594,13.594-23.875,29.406-30.719,46.297c-10.234,25.266-12.781,52.844-7.672,79.219
		c2.547,13.219,7.031,26.156,13.453,38.359c6.406,12.203,14.75,23.672,24.938,33.844c13.594,13.578,29.406,23.891,46.266,30.688
		c25.281,10.266,52.844,12.813,79.25,7.703c13.234-2.563,26.156-7.047,38.344-13.453c12.203-6.391,23.672-14.75,33.859-24.938
		l66.25-66.266c13.344-13.344,13.344-35.016,0-48.359C291.54,343.066,269.883,343.066,256.54,356.426z"
                        style={{ fill: 'rgb(255, 255, 255)' }}
                      ></path>
                      <path
                        className="st0"
                        d="M342.43,169.567c-13.344-13.344-35.016-13.344-48.375,0l-124.516,124.5c-13.344,13.359-13.344,35.016,0,48.359
		c13.375,13.375,35.047,13.375,48.391,0l124.5-124.5C355.805,204.567,355.805,182.926,342.43,169.567z"
                        style={{ fill: 'rgb(255, 255, 255)' }}
                      ></path>
                    </g>
                  </svg>
                </CopyToClipboard>
                {linkState.copied ? (
                  <div className="clipBoardText">記事リンクをクリップボードにコピーしました</div>
                ) : null}
              </button>
            </div>
            <Contents values={article.node} categoryName={categoryData} parentCategory={parent} recommend={recommend} />
            <Side
              category={categoryData}
              location={locatePath}
              articleId={article.node.id}
              rankingBottomPC={'9906936452'}
              rankingBottomMobile={'4734500820'}
              betweenSelfAndCategoryLIstPC={'1988289697'}
              betweenSelfAndCategoryLIstMobile={'8362126352'}
              sideFixedPC={'6911591114'}
              sideFixedMobile={'6128679794'}
            />
          </main>
          <Breadcrumb breadcrumb={breadcrumb} />
          <Footer />
        </div>
        <Floating />
      </div>
      <Script />
    </>
  )
}
