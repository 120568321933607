import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import LazyLoad from 'react-lazyload'
import React, { useEffect, useState } from 'react'
import { timestampToTime, getResizeArticleUrl, getResizeImgUrl, convertDesc, getEstimatedTime } from '../../util/format'
import animateScroll from 'react-scroll/modules/mixins/animate-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import loadable from '@loadable/component'

const DisplayAdsense = loadable(() => import('../Adsense/DisplayAdsense'))

/**
 * Validate input location
 *
 * @param location string
 * @param regex RegExp
 *
 * @returns {Array<string>}
 */
const regExpData = (location: string, regex: RegExp): Array<string> => {
  const res = location.match(regex)
  return res ? [res[1], res[2]] : ['', '']
}

// PC記事内アドセンスの間隔を9とする。
const adsenseDistancePC = 9
// Mobile記事内アドセンスの間隔を5とする。
const adsenseDistanceMobile = 9
// 前回アドセンス位置。初期値を5とする。
let previousAdsenseIdx = adsenseDistanceMobile

const renderers = {
  code: ({ language, value, index }: any) => {
    const [state, setState] = useState({
      displayAdsense: <></>,
    })

    useEffect(() => {
      if (window) {
        const minWidth = 520
        const isPC = window.innerWidth >= minWidth
        const adsenseDistance = isPC ? adsenseDistancePC : adsenseDistanceMobile
        if (index - previousAdsenseIdx >= adsenseDistance && index <= 90) {
          previousAdsenseIdx = index

          let pcAdType = 'display-auto'
          switch (Math.floor(Math.random() * 3)) {
            case 0:
            default:
              pcAdType = 'display-auto'
              break
            case 1:
              pcAdType = 'display-longWidth'
              break
            case 2:
              pcAdType = 'display-double'
              break
          }

          setState({
            displayAdsense: (
              <DisplayAdsense
                slot={{
                  PC: '6706263909',
                  Mobile: '1667837447',
                }}
                position={{
                  PC: {
                    top: 16,
                    bottom: 16,
                    left: 0,
                    right: 0,
                  },
                  Mobile: {
                    top: 12,
                    bottom: 12,
                    left: 0,
                    right: 0,
                  },
                }}
                type={{
                  PC: pcAdType,
                  Mobile: 'display-auto',
                }}
                isSticky={false}
              />
            ),
          })
        }
      }
    })

    const textArray = value.split('\n')
    const added: Array<number> = []
    const removed: Array<number> = []
    for (let i = 0; i < textArray.length; i++) {
      if (textArray[i][0] === '+') {
        added.push(i + 1)
      }
      if (textArray[i][0] === '-') {
        removed.push(i + 1)
      }
    }

    return (
      <>
        {state.displayAdsense}
        <SyntaxHighlighter
          customStyle={{ borderWidth: 0 }}
          language={language}
          showLineNumbers
          children={value}
          wrapLines={true}
          style={atomDark}
          lineProps={(lineNumber) => {
            const style = {
              display: 'block',
              backgroundColor: 'rgb(29, 31, 33)',
              color: 'white',
              width: 'fit-content',
            }
            if (added.includes(lineNumber)) {
              style.backgroundColor = 'rgba(0, 146, 27, .2)'
            } else if (removed.includes(lineNumber)) {
              style.backgroundColor = 'rgba(218, 54, 50, .2)'
            }
            return { style }
          }}
        />
      </>
    )
  },
  link: (props: any) => {
    const youtubeData = regExpData(props.href, /^https:\/\/www.youtube.com\/embed\/(.+)\/(.+)$/)
    if (youtubeData[0] && youtubeData[1]) {
      return (
        <div className="iframe-wrapper">
          <iframe
            width="560"
            height="315"
            src={'https://www.youtube.com/embed/' + youtubeData[0]}
            title={youtubeData[1]}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          />
        </div>
      )
    }

    const internalData = regExpData(props.href, /^https:\/\/kuroro.blog\/.+(.{20})\/$/)
    if (internalData[0]) {
      const data = useStaticQuery(
        graphql`
          query {
            allArticle(sort: { order: DESC, fields: updatedAt___seconds }) {
              edges {
                node {
                  category
                  description
                  id
                  thumbnail
                  title
                  article
                  createdAt {
                    seconds
                  }
                  updatedAt {
                    seconds
                  }
                }
              }
            }
          }
        `
      )

      const article = data.allArticle.edges.find(function (o: any) {
        return o.node.id === internalData[0]
      })

      // 内部リンク && テキストリンクの判定に利用
      const textIncludeLink = regExpData(props.node.children[0].value, /^https:\/\/kuroro.blog\/.+(.{20})\/$/)

      if (article.node && textIncludeLink[0]) {
        const img = (
          <img
            alt={article.node.title + 'のサムネイル画像'}
            src={getResizeImgUrl(article.node.thumbnail, '120x120')}
            srcSet={getResizeImgUrl(article.node.thumbnail, '350x232') + ' 2x'}
            referrerPolicy="no-referrer"
            height={120}
            width={180}
          />
        )
        return (
          <div className="internalCard">
            <a className="internalCard__a" href={props.href}>
              <span className="internalCard__span">この記事も読まれています</span>
              <div className="internalCard__wrap">
                <div className="internalCard__thumbnail">
                  <>
                    <LazyLoad>{img}</LazyLoad>
                    <noscript>
                      <style>{`.lazyload-placeholder { display: none; }`}</style>
                      {img}
                    </noscript>
                  </>
                </div>
                <div className="internalCard__info">
                  <p className="internalCard__info--title internalCard__info--text">
                    <strong>{article.node.title}</strong>
                  </p>
                  <p className="internalCard__info--intro internalCard__info--text">
                    {convertDesc(article.node.description, 60)}
                  </p>
                </div>
              </div>
            </a>
          </div>
        )
      } else {
        return <a href={props.href}>{props.node.children[0].value}</a>
      }
    }

    const githubData = regExpData(props.href, /^https:\/\/github.com\/kuroroblog\/(.+)$/)

    if (githubData[0]) {
      return <a href={props.href}>{props.node.children[0].value}</a>
    }

    return (
      <a href={props.href} rel="nofollow">
        {props.node.children.map((child: any) => {
          if (child['type'] === 'text') {
            return child.value
          }
          if (child['type'] === 'image') {
            const img = (
              <img
                src={getResizeArticleUrl(child.url, '690x460')}
                alt={child.alt}
                srcSet={getResizeArticleUrl(child.url, '1380x920') + ' 2x'}
                referrerPolicy="no-referrer"
                width={'100%'}
                height={400}
              />
            )

            return (
              <>
                <LazyLoad>{img}</LazyLoad>
                <noscript>
                  <style>{`.lazyload-placeholder { display: none; }`}</style>
                  {img}
                </noscript>
              </>
            )
          }
        })}
      </a>
    )
  },
  image: (props: any) => {
    const img = (
      <img
        src={getResizeArticleUrl(props.src, '690x460')}
        alt={props.alt}
        srcSet={getResizeArticleUrl(props.src, '1380x920') + ' 2x'}
        referrerPolicy="no-referrer"
        width={'100%'}
        height={400}
      />
    )
    return (
      <div className="article__img">
        <LazyLoad>{img}</LazyLoad>
        <noscript>
          <style>{`.lazyload-placeholder { display: none; }`}</style>
          {img}
        </noscript>
      </div>
    )
  },
  heading: ({ level, children, index }: any) => {
    const [state, setState] = useState({
      displayAdsense: <></>,
    })

    useEffect(() => {
      if (window) {
        const minWidth = 520
        const isPC = window.innerWidth >= minWidth
        const adsenseDistance = isPC ? adsenseDistancePC : adsenseDistanceMobile
        if (index - previousAdsenseIdx >= adsenseDistance && index <= 90) {
          previousAdsenseIdx = index

          let pcAdType = 'display-auto'
          switch (Math.floor(Math.random() * 3)) {
            case 0:
            default:
              pcAdType = 'display-auto'
              break
            case 1:
              pcAdType = 'display-longWidth'
              break
            case 2:
              pcAdType = 'display-double'
              break
          }

          setState({
            displayAdsense: (
              <DisplayAdsense
                slot={{
                  PC: '6706263909',
                  Mobile: '1667837447',
                }}
                position={{
                  PC: {
                    top: 16,
                    bottom: 16,
                    left: 0,
                    right: 0,
                  },
                  Mobile: {
                    top: 12,
                    bottom: 12,
                    left: 0,
                    right: 0,
                  },
                }}
                type={{
                  PC: pcAdType,
                  Mobile: 'display-auto',
                }}
                isSticky={false}
              />
            ),
          })
        }
      }
    })

    const text = children[0].props.value
    switch (level) {
      case 1:
        return (
          <>
            {state.displayAdsense}
            <h1 id={'a' + index}>{text}</h1>
          </>
        )
      case 2:
        return (
          <>
            {state.displayAdsense}
            <h2 id={'a' + index}>{text}</h2>
          </>
        )
      case 3:
        return (
          <>
            {state.displayAdsense}
            <h3 id={'a' + index}>{text}</h3>
          </>
        )
      default:
        return (
          <>
            {state.displayAdsense}
            <h4 id={'a' + index}>{text}</h4>
          </>
        )
    }
  },
}

const getHeading = (article: string): any => {
  const data = article.split(/\r\n|\n/).filter(function (d) {
    return d !== ''
  })
  const headingList = []
  let nest = []
  let idxCount = 0
  let listFlag = false
  let olFlag = false
  let codeFlag = false
  let tableFlag = false
  let articleListIndexCnt = 0
  for (let i = 0; i < data.length; i++) {
    if (data[i].match(/```/) && !codeFlag) {
      codeFlag = true
      continue
    } else {
      if (data[i].match(/```/) && codeFlag) {
        codeFlag = false
        idxCount++
        continue
      }
      if (codeFlag) {
        continue
      }
    }

    if (data[i].match(/- /)) {
      listFlag = true
    } else {
      if (listFlag) {
        idxCount++
      }
      listFlag = false
    }
    if (listFlag) {
      continue
    }

    if (data[i].match(/\|.+\|/)) {
      tableFlag = true
    } else {
      tableFlag = false
    }
    if (tableFlag) {
      continue
    }

    if (data[i].match(/1\./)) {
      olFlag = true
    } else {
      if (olFlag) {
        idxCount++
      }
      olFlag = false
    }
    if (olFlag) {
      continue
    }

    if (data[i].indexOf('## ') !== -1) {
      if (data[i].indexOf('### ') !== -1) {
        nest.push({
          text: data[i].replace('### ', ''),
          num: idxCount,
        })
        articleListIndexCnt++
      } else {
        if (nest.length > 0) {
          headingList[headingList.length - 1].nest = nest
          nest = []
        }
        headingList.push({
          text: data[i].replace('## ', ''),
          num: idxCount,
          nest: [],
        })
        articleListIndexCnt++
      }
    }

    idxCount++
  }

  return [headingList, articleListIndexCnt]
}

const scrollHeading = (className: string) => {
  const targetElem = document.getElementById(className)
  if (targetElem) {
    const rect = targetElem.getBoundingClientRect()
    animateScroll.scrollTo(rect.top + document.documentElement.scrollTop - 5)
  }
}

const toggleArticleListIndex = (
  toggleArticleListIndexIdName: string,
  isClosedIdName: string,
  ArticleListIndexListClassName: string
) => {
  const targetElem = document.getElementById(isClosedIdName)
  let toggleText = '目次を開く⬇︎'
  let toggleMargin = '0px'
  if (targetElem) {
    targetElem.removeAttribute('id')
    toggleText = '目次を閉じる⬆︎'
    toggleMargin = '-24px 0px 16px'
  } else {
    document.getElementsByClassName(ArticleListIndexListClassName)[0].setAttribute('id', isClosedIdName)
  }

  const doc = document.getElementById(toggleArticleListIndexIdName)
  if (doc) {
    doc.innerText = toggleText
    doc.style.margin = toggleMargin
  }
}

const Contents = (props: {
  values: {
    article: string
    category: string
    description: string
    id: string
    thumbnail: string
    title: string
    updatedAt: {
      seconds: number
    }
    createdAt: {
      seconds: number
    }
  }
  categoryName: any
  parentCategory: any
  recommend: Array<{ desc: string; title: string; thumbnail: string; url: string }>
}): JSX.Element => {
  const createdTime = timestampToTime(props.values.createdAt.seconds)
  const updatedTime = timestampToTime(props.values.updatedAt.seconds)

  const article = props.values.article
  const estimatedTime = getEstimatedTime(article)

  const [heading, articleListIndexCnt] = getHeading(article)

  // 見出しを閉じた状態にしておくべきか、判定するために必要な数値
  const isClosedArticleListIndexCnt = 9
  const isClosedIdName = 'article__listIndex__list--isClosed'
  const ArticleListIndexListClassName = 'article__listIndex__list'
  const toggleArticleListIndexIdName = 'article__listIndex__list--toggle'

  const img = (
    <img
      src={getResizeImgUrl(props.values.thumbnail, '350x232')}
      alt={props.values.title}
      srcSet={getResizeImgUrl(props.values.thumbnail, '690x460') + ' 2x'}
      referrerPolicy="no-referrer"
      className={'article__thumbnail'}
      width={680}
      height={400}
    />
  )

  const parentCategoryHtml = props.parentCategory ? (
    <a className="article__category" href={'/' + props.parentCategory.name + '/'}>
      {props.parentCategory.value}
    </a>
  ) : (
    <></>
  )

  return (
    <article className="article">
      <div className="article__introduce">
        {img}
        <noscript>
          <style>{`.lazyload-placeholder { display: none; }`}</style>
          {img}
        </noscript>
        <p className="article__date" style={{ marginTop: 8 }}>
          <time dateTime={createdTime}>作成日 : {createdTime}</time>
          <br />
          <time dateTime={updatedTime}>更新日 : {updatedTime}</time>
          <br />
          <i className="fas fa-clock" aria-hidden="true" />
          <span>{' ' + estimatedTime} min read</span>
          {parentCategoryHtml}
          <a className="article__category" href={'/' + props.categoryName.name + '/'}>
            {props.categoryName.value}
          </a>
        </p>
        <h1 className="article__title">{props.values.title}</h1>
        <p className="article__lead">{props.values.description}</p>
      </div>
      <div className="article__listIndex">
        <span className="article__listIndex__header">目次</span>
        <ol
          id={articleListIndexCnt >= isClosedArticleListIndexCnt ? isClosedIdName : ''}
          className={ArticleListIndexListClassName}
        >
          {heading &&
            heading.map(
              (head: {
                text: string
                num: number
                nest: Array<{
                  text: string
                  num: number
                }>
              }) => {
                if (head.nest.length > 0) {
                  return (
                    <li className="article__listIndex__item" key={head.num}>
                      <a onClick={() => scrollHeading(`a${head.num}`)}>
                        <span>{head.text}</span>
                      </a>
                      <ol className="article__listIndex__nest">
                        {head.nest &&
                          head.nest.map((n: { text: string; num: number }) => {
                            return (
                              <li className="article__listIndex__nestItem" key={n.num}>
                                <a onClick={() => scrollHeading(`a${n.num}`)}>
                                  <span>{n.text}</span>
                                </a>
                              </li>
                            )
                          })}
                      </ol>
                    </li>
                  )
                } else {
                  return (
                    <li className="article__listIndex__item" key={head.num}>
                      <a onClick={() => scrollHeading(`a${head.num}`)}>
                        <span>{head.text}</span>
                      </a>
                    </li>
                  )
                }
              }
            )}
        </ol>
        {articleListIndexCnt >= isClosedArticleListIndexCnt && (
          <div
            id={toggleArticleListIndexIdName}
            onClick={() =>
              toggleArticleListIndex(toggleArticleListIndexIdName, isClosedIdName, ArticleListIndexListClassName)
            }
          >
            目次を開く⬇︎
          </div>
        )}
      </div>
      <DisplayAdsense
        slot={{
          PC: '3301371360',
          Mobile: '9131448394',
        }}
        position={{
          PC: {
            top: 0,
            bottom: 24,
            left: 0,
            right: 0,
          },
          Mobile: {
            top: 0,
            bottom: 12,
            left: 0,
            right: 0,
          },
        }}
        type={{
          PC: 'display-double',
          Mobile: 'display-auto',
        }}
        isSticky={false}
      />
      {props.recommend.length > 0 && (
        <>
          <section>
            <p className="recommendTitle">{'執筆者 - おすすめの記事' + props.recommend.length + '選'}</p>
            {props.recommend.map(
              (article: { desc: string; title: string; thumbnail: string; url: string }, idx: number) => {
                const img = (
                  <img
                    alt={article.title}
                    src={getResizeImgUrl(article.thumbnail, '120x120')}
                    srcSet={getResizeImgUrl(article.thumbnail, '350x232') + ' 2x'}
                    referrerPolicy="no-referrer"
                    width={60}
                    height={60}
                  />
                )

                return (
                  <div className="internalCard" key={idx} style={{ margin: '16px 0' }}>
                    <a className="internalCard__a" href={article.url}>
                      <span className="internalCard__span">この記事も読まれています</span>
                      <div className="internalCard__wrap">
                        <div className="internalCard__thumbnail">
                          <>
                            <LazyLoad>{img}</LazyLoad>
                            <noscript>
                              <style>{`.lazyload-placeholder { display: none; }`}</style>
                              {img}
                            </noscript>
                          </>
                        </div>
                        <div className="internalCard__info">
                          <p className="internalCard__info--title internalCard__info--text">
                            <strong>{article.title}</strong>
                          </p>
                          <p className="internalCard__info--intro internalCard__info--text">
                            {convertDesc(article.desc, 60)}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              }
            )}
          </section>
        </>
      )}

      {props.recommend.length >= 3 && (
        <DisplayAdsense
          slot={{
            PC: '3194289419',
            Mobile: '8255044404',
          }}
          position={{
            PC: {
              top: 0,
              bottom: 24,
              left: 0,
              right: 0,
            },
            Mobile: {
              top: 0,
              bottom: 12,
              left: 0,
              right: 0,
            },
          }}
          type={{
            PC: 'display-double',
            Mobile: 'display-auto',
          }}
          isSticky={false}
        />
      )}

      <ReactMarkdown plugins={[gfm]} children={article} renderers={renderers} includeNodeIndex />
      <a style={{ paddingTop: 4, display: 'block' }} href="/contacts/user/">
        記事に関するお問い合わせ
      </a>
      <DisplayAdsense
        slot={{
          PC: '5007482501',
          Mobile: '1951796526',
        }}
        position={{
          PC: {
            top: 16,
            bottom: 8,
            left: 0,
            right: 0,
          },
          Mobile: {
            top: 16,
            bottom: 16,
            left: 0,
            right: 0,
          },
        }}
        type={{
          PC: 'display-double',
          Mobile: 'display-auto',
        }}
        isSticky={false}
      />
    </article>
  )
}
export default Contents
